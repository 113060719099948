th,
td {
	font-size: 16px !important;
}

.taketeam {
	display: flex;
	margin: 12px;
	color: white;
	background-color: #AE2573;
}
.taketeam div {
	padding: 12px;
	flex-basis: 100%;
	text-align: center;
}
